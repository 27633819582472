<template>
  <div class="table-wrap">
    <el-table
      border
      :data="list"
      style="width: 100%"
      row-key="id"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0' }"
    >
      <el-table-column
        prop="tempId"
        width="50px"
        label="ID"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="date"
        label="时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="nickname"
        label="管理专员"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="regCount"
        align="center"
        label="注册商家"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.regCount ? scope.row.regCount : '-'}}
        </template>
      </el-table-column>
      <el-table-column
        prop="consumptionAllMoney"
        label="商家消费（元）"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.consumptionAllMoney ? scope.row.consumptionAllMoney : '-'}}
        </template>
      </el-table-column>
      <el-table-column
        prop="firstRechargeMoney"
        align="center"
        label="首充金额（元）"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.firstRechargeMoney ? scope.row.firstRechargeMoney : '-'}}
        </template>
      </el-table-column>
<!--      <el-table-column prop="uvToReg" align="center" label="商家转化">-->
<!--        <template slot-scope="scope">-->
<!--          {{ scope.row.uvToReg | ratio }}-->
<!--        </template></el-table-column-->
<!--      >-->
      <el-table-column
        prop="riskControlMoney"
        align="center"
        label="风控充值（元）"
      >
        <template slot-scope="scope">
          {{ scope.row.riskControlMoney ? scope.row.riskControlMoney : '-'}}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="riskControlMoney"
        label="风控充值提成（元）"
      >
        <template slot-scope="scope">{{
            scope.row.riskControlMoney ? (scope.row.riskControlMoney * scope.row.oneLevel / 100).toFixed(2) : '-'
          }}</template>
      </el-table-column>
      <el-table-column
        prop="starRechargMoney"
        align="center"
        label="星币充值（元）"
        v-if="pro"
      >
        <template slot-scope="scope">
          {{ scope.row.starRechargMoney ? scope.row.starRechargMoney : '-'}}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="星币充值提成（元）"
        v-if="pro"
      >
        <template slot-scope="scope">{{
            scope.row.starRechargMoney ? (scope.row.starRechargMoney * scope.row.oneLevel / 100).toFixed(2) : '-'
          }}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  filters: {
    ratio(a, b) {
      if (b) a = a / b;
      return a.toFixed(2) + "%";
    }
  }
};
</script>

<style lang="scss" scoped></style>
