<template>
  <div class="main-wrap">
    <!-- 搜索 -->
    <search-form @search="search"></search-form>
    <!-- 角色列表 -->
    <perform-table :list="list"></perform-table>
     <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
import searchForm from './components/search-form';
import performTable from './components/perform-table';
import pagination from '@/mixins/pagination';

import service from './api';
export default {
  mixins: [pagination],

  data() {
    return {
      list: [],
      page: {}
    };
  },
  components: {
    searchForm,
    performTable
  },
  methods: {
    init() {
      let initParams = { pageNum: 1 };
      this.getList(initParams);
    },
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      params.adminId = sessionStorage.getItem('id')
      return service.getList(params).then(res => {
        let page ={
          'allNum': res.total,
          'pageNum':res.pages,
          'pageSize': res.size
        }
        let list = res.records
        let lastTime = '';
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
          item.date = item.date === lastTime ? '-' : (lastTime = item.date);
        });
        this.list = list;
        this.page = page;
      });
    },
    // 筛选时间
    flatDate(list) {
      let dates = [];
      list.forEach(item => {
        dates.push(item.date);
      });
      dates = [...new Set(dates)];
      return dates;
    },
    search(data) {
      let params = Object.assign(data, { pageNum: 1 });
      this.searchData = data;
      return this.getList(params);
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
</style>
