import API from "@/api";

export default {
  getList: params => {
    return API.requestPost({
      url: "sponsored/achievementList",
      data: params,
      auth: true
    });
  }
};
